import { ActionIcon, Box, Flex, Group, Text, Tooltip } from "@mantine/core";
import { IconPlayerPlay } from "@tabler/icons-react";
import { useNavigate } from "@tanstack/react-location";
import { useLiveQuery } from "dexie-react-hooks";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import { db } from "../db";
import { createChatCompletion } from "../utils/openai";
import { DeletePromptModal } from "./DeletePromptModal";
import { EditPromptModal } from "./EditPromptModal";
import { config } from "../utils/config";

export function Prompts({
  onPlay,
  search,
}: {
  onPlay: () => void;
  search: string;
}) {
  const navigate = useNavigate();
  const prompts = useLiveQuery(() =>
    db.prompts.orderBy("createdAt").reverse().toArray()
  );
  const filteredPrompts = useMemo(
    () =>
      (prompts ?? []).filter((prompt) => {
        if (!search) return true;
        return (
          prompt.title.toLowerCase().includes(search) ||
          prompt.content.toLowerCase().includes(search)
        );
      }),
    [prompts, search]
  );
  const apiKey = process.env.REACT_APP_API_KEY;

  return (
    <>
      {filteredPrompts.map((prompt) => (
        <Flex
          key={prompt.id}
          sx={(theme) => ({
            marginTop: 1,
            padding: theme.spacing.xs,
            "&:hover": {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[1],
            },
          })}
        >
          <Box
            sx={(theme) => ({
              flexGrow: 1,
              width: 0,
              fontSize: theme.fontSizes.sm,
            })}
          >
            <Text
              weight={500}
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {prompt.title}
            </Text>
            <Text
              color="dimmed"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {prompt.content}
            </Text>
          </Box>
          <Group spacing="none">
            <Tooltip label="Create new chat from prompt">
              <ActionIcon
                size="lg"
                onClick={async () => {
                  if (!apiKey) return;
                  const id = nanoid();
                  await db.chats.add({
                    id,
                    description: "New chat",
                    totalTokens: 0,
                    createdAt: new Date(),
                  });
                  await db.messages.add({
                    id: nanoid(),
                    chatId: id,
                    content: prompt.content,
                    role: "user",
                    createdAt: new Date(),
                  });
                  navigate({ to: `/chats/${id}` });
                  onPlay();

                  const result = await createChatCompletion(apiKey, [
                    {
                      role: "system",
                      content:
                        `Welcome! I'm ImmoBot, developed by Digimotum Plus specifically for Dr. Klein to assist with all your inquiries about our services and offerings found on https://www.drklein.de/. My goal is to provide accurate and relevant information about Dr. Klein's finance and insurance solutions.

                        Capabilities:
                        
                        Answering questions related to services provided by Dr. Klein.
                        Offering guidance on finance and insurance topics relevant to Dr. Klein's expertise.
                        Directing users to Dr. Klein's advisors for further assistance when needed.
                        Limitations:
                        
                        I'll only address queries directly related to Dr. Klein.
                        I'm here to provide general information and cannot replace personalized advice from a professional advisor.
                        My responses are based on the data I've been trained on and the specific details available on Dr. Klein's website.
                        Usage Instructions:
                        
                        Please clearly state your question. I can understand and respond to both English and German.
                        For complex or detailed inquiries where a personalized consultation is recommended, I'll guide you to contact a Dr. Klein advisor.
                        Friendly Reminder:
                        While I strive to provide helpful information, my advice should not be the sole basis for your decisions. For detailed and personalized advice, connecting with a Dr. Klein advisor is always the best course of action.`  
                    },
                    { role: "user", content: prompt.content },
                  ]);

                  const resultDescription =
                    result.data.choices[0].message?.content;
                  await db.messages.add({
                    id: nanoid(),
                    chatId: id,
                    content: resultDescription ?? "unknown reponse",
                    role: "assistant",
                    createdAt: new Date(),
                  });

                  if (result.data.usage) {
                    await db.chats.where({ id: id }).modify((chat) => {
                      if (chat.totalTokens) {
                        chat.totalTokens += result.data.usage!.total_tokens;
                      } else {
                        chat.totalTokens = result.data.usage!.total_tokens;
                      }
                    });
                  }
                }}
              >
                <IconPlayerPlay size={20} />
              </ActionIcon>
            </Tooltip>
            <EditPromptModal prompt={prompt} />
            <DeletePromptModal prompt={prompt} />
          </Group>
        </Flex>
      ))}
    </>
  );
}
